import { Title } from '@solidjs/meta';
import { Heading } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Content } from '../../../components/content';

export default function CareersPage() {
	return (
		<NoHydration>
			<Title>Team Troon | Troon</Title>

			<Content>
				<Heading as="h1">Team Troon</Heading>
			</Content>
		</NoHydration>
	);
}
